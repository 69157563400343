<template>
  <div class="section section__company">
    <div class="section__wrapper">
      <div class="title-with-icon title-with-icon_menu mb-5">
        <div class="title-with-icon__icon title-with-icon__icon_back"></div>
        <div class="title-with-icon__title"><a href="/sveden">Сведения об образовательной организации</a></div>
        <div class="title-with-icon__delimeter">/</div>
        <div class="title-with-icon__title">Основные сведения</div>
      </div>

      <div class="content_18 mb-3">
        <div class="mb-1">Основные сведения об образовательной организации <span itemprop="fullName">Автономная некоммерческая организация
          дополнительного профессионального образования «Школа экспорта Акционерного общества «Российский экспортный
          центр»</span>
        </div>
        <div class="mb-1">Сокращенное наименование: <span itemprop="shortName">АНО ДПО «Школа экспорта Акционерного общества «Российский экспортный
          центр» (Школа экспорта РЭЦ)</span>
        </div>
        <div class="mb-1">Наименование на английском языке: Autonomous non-profit organization of additional professional
          education «Export school of Russian Export Center Joint Stock Company»
        </div>
      </div>

      <div class="mb-5 d-block d-sm-none">
        <div class="row">
          <div class="col"> </div>
          <div class="col-auto">
            <div class="table-wrapper_indicator"></div>
          </div>
        </div>
      </div>
      <div class="table-wrapper table-wrapper_scrolled mb-7">
        <table class="table_bordered">
          <tbody>
          <tr>
            <td>Дата создания образовательной организации
            </td>
            <td><strong class="content_700" itemprop="regDate">18.07.2016</strong>
            </td>
          </tr>
          <tr>
            <td>Адрес местонахождения образовательной организации
            </td>
            <td><strong itemprop="address" class="content_700">123610, Москва, Краснопресненская наб. 12, подъезд 9</strong></td>
          </tr>
          <tr>
            <td>Филиалы образовательной организации</td>
            <td><strong class="content_700">Филиалов нет</strong>
            </td>
          </tr>
          <tr>
            <td>Режим, график работы</td>
            <td><strong class="content_700" itemprop="workTime">понедельник-пятница: 9:00 до 18:00</strong>
            </td>
          </tr>
          <tr>
            <td>Контактные телефоны</td>
            <td><strong class="content_700"><a itemprop="telephone" href="tel:+7 (495) 937-47-42">+7 (495) 937-47-42</a></strong>
            </td>
          </tr>
          <tr>
            <td>Факсы
            </td>
            <td><strong class="content_700">Отсутствует</strong>
            </td>
          </tr>
          <tr>
            <td>Адреса электронной почты</td>
            <td><strong class="content_700"><a href="mailto:info@exportedu.ru" itemprop="email">info@exportedu.ru</a></strong></td>
          </tr>
          <tr>
            <td>Места осуществления образовательной деятельности</td>
            <td itemprop="address">
              <span itemprop="addressPlace">123610, Москва, Краснопресненская наб. 12, подъезд 9</span><br/>
              <span itemprop="addressPlace">119285, Москва, Воробьевское шоссе, 6А</span><br/>
              <span itemprop="addressPlace">117997, Москва, Стремянный пер., 36</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="mb-5 d-block">
        <div class="row">
          <div class="col">
            <div class="content_title content_700">Учредители</div>
          </div>
<!--          <div class="col-auto">
            <div class="table-wrapper_indicator"></div>
          </div>-->
        </div>
      </div>

      <div class="table-wrapper table-wrapper_scrolled mb-7">
        <table class="table_bordered" itemprop="uchredLaw">
          <thead>
          <tr>
            <th>Наименование учредителя</th>
            <th>ФИО руководителя учредителя (ей) образовательной организации</th>
            <th>Адрес местонахождения учредителя(ей)</th>
            <th>Контактные телефоны</th>
            <th>Адрес электронной почты</th>
            <th>Адрес сайта учредителя(ей) в сети «Интернет»</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td itemprop="nameUchred">Акционерное общество «Российский экспортный центр»</td>
            <td><strong class="content_700">Никишина Вероника Олеговна</strong></td>
            <td itemprop="addressUchred">123610, г. Москва, Краснопресненская наб., д. 12</td>
            <td class="text-nowrap" itemprop="telUchred">+7 (495) 937-4747</td>
            <td itemprop="mailUchred">info@exportcenter.ru</td>
            <td itemprop="websiteUchred">www.exportcenter.ru</td>
          </tr>
          <tr>
            <td itemprop="nameUchred">Акционерное общество «Российское агентство по страхованию экспортных кредитов и инвестиций»</td>
            <td><strong class="content_700">Гусаков            Никита Валерьевич</strong></td>
            <td itemprop="addressUchred">123610, г. Москва, Краснопресненская наб., д. 12</td>
            <td class="text-nowrap" itemprop="telUchred">+7 (495) 783-11-88</td>
            <td itemprop="mailUchred">info@exiar.ru</td>
            <td itemprop="websiteUchred">www.exiar.ru/</td>
          </tr>
          <tr>
            <td itemprop="nameUchred">Государственный специализированный Российский экспортно-импортный банк (акционерное общество)</td>
            <td><strong class="content_700">Сахарова            Татьяна Геннадиевна</strong></td>
            <td itemprop="addressUchred">123610, г. Москва, Краснопресненская наб., д. 12</td>
            <td class="text-nowrap" itemprop="telUchred">+7 (495) 967-07-67</td>
            <td itemprop="mailUchred">mailbox@eximbank.ru</td>
            <td itemprop="websiteUchred">www.eximbank.ru/</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="my-5 d-block d-md-none">
        <a class="history-back" href="/sveden">Вернуться</a>
      </div>

      <div class="section__subsection_blue section__company-nav">
        <div class="section__subsection-wrapper">
          <company-navigation/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import ApiService from "../../../services/api.service";
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "InfoBasic",
  components: {CompanyNavigation},
  mounted() {
    this.$parent.init()
  }
}
</script>